
.NoteListMain ul {
    list-style-type: none;
    margin: 0;
    padding: 10px;
  }
  
  .NoteListMain__button-container {
    max-width: 380px;
    margin: 0 10px;
  }
  
  .NoteListMain__add-note-button {
    margin: 0 auto 20px;
  }
  .NoteListMain__add-note-button:hover {
    background-color: #29862e;
    color: white;
  }
  